<template>
  <div class="main">
    <div class="row px-4 py-4 mb-3">
      <div
        v-for="file in files"
        :key="file._id"
        class="contentContainer col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-4"
      >
        <div class="moreOptionsContainer d-flex justify-content-end">
          <svg
            @click="activeOptions = file._id"
            v-if="file.extension !== 'FOLDER'"
            class="bi"
            id="addBranch"
            fill="currentColor"
          >
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#three-dots-vertical"
            />
          </svg>
          <ul
            class="dropdown-menu menuOffset"
            @mouseleave="hideAllOptions"
            :class="{ show: activeOptions === file._id }"
          >
            <li class="dropdown-item editOption" @click="editFile(file._id)">
              <svg fill="currentColor" class="bi">
                <use xlink:href="/img/icons/bootstrap-icons.svg#pencil-fill" />
              </svg>
              Editar
            </li>
            <li
              class="dropdown-item printOption"
              @click="downloadFile(file._id)"
            >
              <svg fill="currentColor" class="bi">
                <use xlink:href="/img/icons/bootstrap-icons.svg#printer-fill" />
              </svg>
              Descargar
            </li>
            <li
              class="dropdown-item deleteOption"
              @click="deleteFile(file._id)"
            >
              <svg fill="currentColor" class="bi">
                <use
                  xlink:href="/img/icons/bootstrap-icons.svg#x-square-fill"
                />
              </svg>
              Eliminar
            </li>
          </ul>
        </div>
        <div class="iconMainContainer d-flex flex-column">
          <svg
            v-if="file.extension === 'FOLDER'"
            class="bi"
            id="addBranch"
            fill="currentColor"
            @click="navigateFolder(file._id)"
          >
            <use xlink:href="/img/icons/bootstrap-icons.svg#folder-fill" />
          </svg>
          <svg
            @click="downloadFile(file._id)"
            v-else-if="file.extension === 'PDF'"
            class="bi"
            id="addBranch"
            fill="currentColor"
          >
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#file-earmark-text-fill"
            />
          </svg>
          <svg
            @click="downloadFile(file._id)"
            v-else-if="file.extension === 'MP4'"
            class="bi"
            id="addBranch"
            fill="currentColor"
          >
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#file-earmark-play-fill"
            />
          </svg>
          <svg
            @click="downloadFile(file._id)"
            v-else
            class="bi"
            id="addBranch"
            fill="currentColor"
          >
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#file-earmark-image-fill"
            />
          </svg>
          <p class="text-center px-2" @click="navigateFolder(file._id)">
            {{ file.name }}
          </p>
        </div>
      </div>
      <div
        v-if="plusButton && !disableCreateFile"
        class="contentContainer col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-4"
      >
        <div class="moreOptionsContainer d-flex justify-content-end"></div>
        <div class="iconMainContainer d-flex flex-column">
          <svg
            class="bi addFile"
            id="addBranch"
            fill="currentColor"
            @click="createFile()"
          >
            <use xlink:href="/img/icons/bootstrap-icons.svg#plus-circle" />
          </svg>
          <p class="text-center px-2"></p>
        </div>
        <input
          @change="getFileClick($event.target.files)"
          type="file"
          name="uploadFile"
          accept=".png,.jpg,.jpeg,.gif,.webp,.pdf"
          id="uploadFile"
          hidden
        />
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.moreOptionsContainer {
  height: 30px;
  width: 94%;
  margin-left: 3%;
  background-color: var(--secondary-bg);
  position: relative;
}
.moreOptionsContainer svg {
  cursor: pointer;
  height: 80%;
  margin-top: 5%;
  width: 40px;
}
.iconMainContainer {
  height: 180px;
  width: 94%;
  margin-left: 3%;
  background-color: var(--secondary-bg);
  overflow: hidden;
}
.iconMainContainer > svg {
  cursor: pointer;
  width: 50%;
  height: 120px;
  margin-left: 25%;
  color: var(--bs-primary);
}
.iconMainContainer p {
  cursor: pointer;
}
.addFile {
  height: 150px !important;
}
.dropdown-menu {
  width: fit-content;
}
.dropdown-menu li:hover {
  background-color: var(--main-bg);
  cursor: pointer;
  text-decoration: underline;
}
.dropdown-menu li svg {
  width: 16px;
  margin-right: 5px;
  height: 16px;
}
.editOption {
  color: gray;
}
.printOption {
  color: var(--bs-yellow);
}
.deleteOption {
  color: var(--bs-danger);
}
.menuOffset {
  top: 40px !important;
  left: 50% !important;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";

export default {
  name: "Document Main Branch",
  components: { Legal },
  data() {
    return {
      files: [],
      plusButton: false,
      activeOptions: false,
      disableCreateFile: false
    };
  },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          if (this.$route.params.fileId) {
            this.plusButton = false; // Ya no se mostrara la funcion de subir archivo
            store.getData("city", branch.city).then(cityData => {
              store
                .getData("file", this.$route.params.fileId)
                .then(fileData => {
                  localStorage.setItem(
                    "currentLocation",
                    "Documentos / " +
                      cityData.name +
                      " / Raíz / " +
                      fileData.name
                  );
                });
            });
          } else {
            store.getData("city", branch.city).then(cityData => {
              localStorage.setItem(
                "currentLocation",
                "Documentos / " + cityData.name + " / Raíz"
              );
            });
          }
        }
      });
    });
  },
  async mounted() {
    const store = new Storage();
    const planData = await store.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const fileData = await store.getData("file");
    const limitSize = planData.restrictions.storage;
    let totalSize = 0;
    fileData.forEach(file => {
      totalSize += file.size;
    });
    if (totalSize >= limitSize) {
      this.disableCreateFile = true;
      let tempLimitSize = limitSize / 1000000;
      tempLimitSize = Math.round(tempLimitSize * 100) / 100;
      let tempTotalSize = totalSize / 1000000;
      tempTotalSize = Math.round(tempTotalSize * 100) / 100;
      Swal.fire({
        title: "No es posible subir nuevos archivos",
        icon: "warning",
        text:
          "La susbscripción que posee tiene un límite de " +
          tempLimitSize +
          "MB, actualmente están registrados " +
          tempTotalSize +
          "MB. Para subir nuevos archivos por favor cambie de plan.",
        showCancelButton: true,
        confirmButtonText: `Cambiar Plan`,
        cancelButtonText: "Continuar",
        cancelButtonColor: "#6c757d"
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: "Subscription" });
        }
      });
    }

    this.files = [];
    store.getData("file").then(fileData => {
      fileData.sort((a, b) => {
        return a.registrationDate - b.registrationDate;
      });
      fileData.forEach(file => {
        if (file.branch === this.$route.params._id) {
          if (!this.$route.params.fileId && !file.parent) {
            this.files.push(file);
          } else if (file.parent === this.$route.params.fileId) {
            this.files.push(file);
          }
        }
      });
    });
  },
  methods: {
    navigateFolder(_id) {
      location.href = "/document/main/" + this.$route.params._id + "/" + _id;
    },
    createFile() {
      this.$router.push({
        name: "Document Main Branch File Create",
        params: {
          _id: this.$route.params._id,
          fileId: this.$route.params.fileId
        }
      });
    },
    editFile(_id) {
      this.$router.push({
        name: "Document Main Branch File Edit",
        params: {
          _id: _id,
          fileId: this.$route.params.fileId
        }
      });
    },
    deleteFile(_id) {
      const store = new Storage();
      store.getData("file", _id).then(file => {
        Swal.fire({
          title: "¿Eliminar Documento?",
          text: "Se eliminará el documento: " + file.name,
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Eliminando documento",
              html:
                "Por favor espere mientras se elimina el documento</b> " +
                '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            store.deleteData("optima", "file", _id).then(() => {
              Swal.fire({
                icon: "success",
                title: "Documento Eliminado",
                text: "Todos los datos han sido sincronizados"
              }).then(() => {
                this.$router.go();
              });
            });
          }
        });
      });
    },
    downloadFile(_id) {
      const store = new Storage();
      store.getData("file", _id).then(fileData => {
        const URL =
          store.baseURL + "/optima/file/download?where=_id=" + fileData._id;
        axios.get(URL, { withCredentials: true }).then(res => {
          window.open(res.data.body.URL);
        });
      });
    },
    hideAllOptions() {
      this.activeOptions = false;
    }
  }
};
</script>
